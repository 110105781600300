import React, { useEffect, useState } from "react";
import { FaBars, FaBell, FaHome, FaList } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { GrDown, GrLogin, GrUp } from "react-icons/gr";
import { Link, Navigate, Outlet } from "react-router-dom";
import { userServicer } from "../../../Commen_Services/user.services";
import DashboardIcon from '@mui/icons-material/Dashboard';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import { Collapse, Icon, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { StarBorder } from "@mui/icons-material";
import InboxIcon from '@mui/icons-material/MoveToInbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { ToastContainer } from "react-toastify";
import logo from "../../../../assets/Images/logo-ethics.png"
import Tooltip from "../../Tooltip/Tooltip";

interface LoginProps {
    onLogOut: (user: any) => void; // Define the type of onLogin function
}


const Sidebar: React.FC<LoginProps> = ({ onLogOut }) => {
    const [isHidden, setIsHidden] = useState(false);

    const [isFull, setIsFull] = useState(false);

    const [userMenu, setUserMenu] = useState([]);
    const [userName,setUserName] = useState();

    const toggleMenu = () => {
        localStorage.setItem('isHidden', String(!isHidden));
        setIsHidden(!isHidden);
        setIsFull(!isFull);
    };

    const navigateToPage = (path: any) => {
        // HERE WRITW A CODE TO NAVIGATE COMPENNET IN REACT TS
    }


    useEffect(() => {
        const fetchUserMenudata = async () => {

            let userMenudata1;
            //let userMenudata1 = JSON.parse(localStorage.getItem('user'));
            const userFromStorage = localStorage.getItem('user');
            
            
            if (userFromStorage !== null) {
                userMenudata1 = JSON.parse(userFromStorage);
            }

            // console.log(JSON.parse(userMenudata1.usermenu));

            let userName = userMenudata1.userName;
            setUserName(userName);
            setUserMenu(JSON.parse(userMenudata1.usermenu));

        }

        fetchUserMenudata();

    }, []);

    const [activeSubMenu, setActiveSubMenu] = useState(null);

    const toggleSubMenu = (itemId: any) => {
        setActiveSubMenu(itemId === activeSubMenu ? null : itemId);
    };
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <>

            <div className="dash">

                {/* <nav className={`sidebar ${isHidden ? 'hidden' : ''}`} style={`@media (max-width:750px) {  ${isHidden ? '.sidebar {display: none;}': '.sidebar {display: block;}'}`}>
                     */}
                {/* <nav className={`sidebar ${isHidden ? 'hidden' : ''}`} > */}
                <nav className={`sidebar ${isHidden ? 'hidden' : ''}`}>
                    <div className="head-logo-content">

                        <div className="logo">
                            <img src={logo} height="40"></img>&nbsp; &nbsp;

                        </div>
                        {/* <div>
                            <span className="logo-name">{userName}</span>
                        </div> */}
                    </div>
                    <div style={{padding:10, display: 'flex', justifyContent: 'center'}}>                   
                        <span className="logo-name">{userName}</span>
                    </div>
                    <hr className="head-hr" />
                    <ul>
                        {userMenu.map((item: any) => {
                            return (
                                <li key={item.Id} >
                                    {item.children === null ? <div style={{ padding: 4, paddingLeft: 8 }}> <Link to={item.Link} style={{ padding: 0 }}><span className="li-content"><div><Icon>{item.Icon}</Icon>&nbsp;&nbsp;</div><div style={{ marginTop: -0.5 }}>{item.Title}</div></span></Link></div> :
                                        <>
                                            <div onClick={() => toggleSubMenu(item.Id)} style={{ marginLeft: 10, cursor: 'pointer' }}>
                                                <span key={item.Id} className="li-content1 "><div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}><Icon>{item.Icon}</Icon> &nbsp;&nbsp;<div style={{ marginTop: -0.5 }}>{item.Title}</div></div><div>

                                                    {activeSubMenu === item.Id ? <GrUp /> : <GrDown />}
                                                </div></span>
                                            </div>
                                            {item.children && (
                                                <ul className={activeSubMenu === item.Id ? 'submenu active' : 'submenu'}>

                                                    {item.children.map((child: any) => (
                                                        <div key={child.Id} className="submemnu-content">
                                                            <Link to={child.Link} style={{ padding: 2 }} >
                                                                <li style={{ cursor: 'pointer' }}><span className="li-content "><div><Icon>{child.Icon}</Icon> &nbsp;&nbsp;</div>{child.Title}</span></li></Link>
                                                        </div>
                                                    ))}
                                                </ul>
                                            )}
                                        </>
                                    }
                                </li>
                            )
                        })}
                    </ul>
                </nav>
                <div className={`head ${isHidden ? 'full' : ''}`}>
                    <div>
                        <div className="head-content">
                            <div>
                                <button className="toggle-button" style={{fontSize:22, padding:5}} onClick={toggleMenu}><FaBars /></button>&nbsp;&nbsp;
                                <Link to="/" ><button className="toggle-button" style={{fontSize:22, padding:5}}><FaHome /></button></Link>
                            </div>
                            <div >
                                <Tooltip text="Profile">
                                    <button className="toggle-button" onClick={toggleMenu}><FaUser /></button>&nbsp;
                                </Tooltip>
                                <Tooltip text="Notification">
                                    <button className="toggle-button" onClick={toggleMenu}><FaBell /></button>&nbsp;
                                </Tooltip>
                                <Tooltip text="LogOut">
                                    <button className="toggle-button" onClick={onLogOut}><GrLogin /></button>&nbsp;
                                </Tooltip>
                            </div>
                        </div>
                        <div className="main-content">
                            <Outlet />


                        </div>

                    </div>

                </div>


            </div>
        </>
    );
}

export default Sidebar;
